import React, { Component } from 'react'
import Axios from 'axios';
import './index.css';
import ProductCard from "./ProductCard/index"

export default class Index extends Component {

    state = {
        products: []
    }

    componentDidMount() {
        Axios.get(`${this.props.urlApi}/api/product/allProducts`)
            .then((r) => {
                this.setState({ products: [...r.data] })
            })
            .catch((e) => {
                console.log(e)
            })
    }

    render() {
        let { products } = this.state;
        return (
                <div className="container-card">
                    {
                        products.map((v, i) => (
                            <ProductCard
                                image={v.image}
                                name={v.name}
                                price={v.price}
                                addItem={() => this.props.addItems(v)}
                                key={i}
                            />
                        ))
                    }
                </div>
        )
    }
}
