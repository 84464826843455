import React, { Component } from 'react';
import currencyFormatter from 'currency-formatter';
import "./index.css";
import carritoPath from "../../../../images/carrito-de-compras.png";

export default class ProductCard extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isMobile: false,
        };
    }

    componentDidMount() {
        // Agregamos un listener para detectar cambios en el tamaño de la pantalla
        window.addEventListener('resize', this.handleResize);
        this.handleResize();
    }

    componentWillUnmount() {
        // Limpieza del listener al desmontar el componente
        window.removeEventListener('resize', this.handleResize);
    }

    handleResize = () => {
        this.setState({ isMobile: window.innerWidth <= 1024 });
    };

    truncarNombre(nombre, maxLength) {
        if (nombre.length <= maxLength) {
            return nombre;
        }
        return nombre.slice(0, maxLength - 3) + '...';
    }

    render() {
        const { image, name, price, addItem } = this.props;
        const { isMobile } = this.state;
        const charactersNumber = isMobile ? 20 : 33
        const nombreTruncado = this.truncarNombre(name, charactersNumber);

        return (
            <div className="product-card">
                <div className="container-img">
                    <img src={image} alt={name} />
                </div>

                <div className="product-name">
                    <div className="div-name">
                        <h5>{nombreTruncado}</h5>
                    </div>

                    <div className="monto">
                        <span>
                            <strong>{currencyFormatter.format(price, { code: 'PEN' })}</strong>
                        </span>
                    </div>

                    <button
                        type=""
                        className="button-add"
                        onClick={addItem}
                    >
                        {isMobile ?
                            <>
                                <span>Agregar</span>
                                <div className='img-carrito'>
                                    <img src={carritoPath} />
                                </div>
                            </>
                            : 'Agregar al carrito'}

                    </button>

                </div>
            </div>
        );
    }
}
